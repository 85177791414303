<div class="bmi">
    <div class="col-md-4 offset-md-4">
        <div class="card">
            <h5>CALCULADORA BMI</h5>
            <div class="row alturaCaja">
                <div class="col-6">
                    <div class="fondo-item" [ngClass]="{ 'sexoDesactivo': sexo === 'Femenino' }">
                        <div class="contenedor" style="cursor: pointer;" (click)="masculino()">
                            <i class="fas fa-mars sexo"></i>
                            <p>Masculino</p>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="fondo-item" [ngClass]="{ 'sexoDesactivo': sexo === 'Masculino' }">
                        <div class="contenedor" style="cursor: pointer;" (click)="femenino()">
                            <i class="fas fa-venus sexo"></i>
                            <p>Femenino</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row alturaCaja">
                <div class="col-12">
                    <div class="fondo-item">
                        <div class="contenedor">
                            <p>Altura</p>
                            <div class="estiloNumero">
                                {{ altura }} <span class="cm">cm</span>
                            </div>
                            <input type="range" [value]="altura" min="120" max="220" class="custom-range"
                                    (input)="cambiarAltura($event)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row alturaCaja">
                <div class="col-6">
                    <div class="fondo-item">
                        <div class="contenedor">
                            <p>Peso</p>
                            <div class="estiloNumero">{{ peso }}</div>
                            <div>
                                <i class="fas fa-minus sumaResta" (click)="peso = peso - 1"></i>
                                <i class="fas fa-plus sumaResta" (click)="peso = peso + 1"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="fondo-item">
                        <div class="contenedor">
                            <p>Edad</p>
                            <div class="estiloNumero">{{ edad }}</div>
                            <div>
                                <i class="fas fa-minus sumaResta" (click)="edad = edad - 1"></i>
                                <i class="fas fa-plus sumaResta" (click)="edad = edad + 1"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <button (click)="calcular()" class="btn btn-primary btn-block btn-lg btn-color">CALCULAR</button>
            </div>
        </div>
    </div>
</div>
